import React from "react";
import {Link} from "react-router-dom";

function Footer() {
	return (
		<footer className="footer">
			<div className="content">
				<div className="footer-left">
					<div className="footer-itm">
						Необходимая правовая информация<br/>
						{new Date().getFullYear()} (с) ИСП РАН
					</div>
				</div>
				<div className="footer-right">
					<Link to="/" className="footer-itm footer-itm--link">Пользовательское соглашение</Link><br/>
					<Link to="/" className="footer-itm footer-itm--link">Информация о хранении и обработке персональных данных</Link>
				</div>
			</div>
		</footer>
	)
}

export default Footer;